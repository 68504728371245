<template>
  <v-card class="centered-panel knowledge">
    <v-list three-line>
      <v-subheader>Technische Fähigkeiten</v-subheader>
      <v-divider> </v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Objektorientierte Programmierung</v-list-item-title>
          <div class="list-content">unter anderem in C#, PHP (Laravel, Phalcon), Golang, Typescript (Vue.js).</div>
        </v-list-item-content>
      </v-list-item>
      <v-divider> </v-divider>
      
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Datenbanken, SQL</v-list-item-title>
          <div class="list-content">insbesondere PostgreSQL und MySQL.</div>
        </v-list-item-content>
      </v-list-item>
      <v-divider> </v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Responsive Design</v-list-item-title>
          <div class="list-content">ein modernes Layout das auf allen üblichen Endgeräten gut bedienbar ist.</div>
        </v-list-item-content>
      </v-list-item>
      <v-divider> </v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Integration und Bereitstellung von (REST) APIs</v-list-item-title>
          <div class="list-content">damit Ihre Software vernetzbar wird.</div>
        </v-list-item-content>
      </v-list-item>
      <v-divider> </v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Agile Softwareentwicklung</v-list-item-title>
          <div class="list-content">damit das Ergebnis Ihren abschließenden Wünschen entspricht.</div>
        </v-list-item-content>
      </v-list-item>
      <v-divider> </v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Kontinuierliche Integration (CI)</v-list-item-title>
          <div class="list-content">für einen geringen Aktualisierungsaufwand, damit ihre Änderungswünsche kurzfristig eingearbeitet und veröffentlicht werden können.
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-divider> </v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Automatische Tests</v-list-item-title>
          <div class="list-content">um die Funktion auch nach Aktualisierungen garantieren zu können.
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'knowledge',

}
</script>

<style scoped>

</style>
